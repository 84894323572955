var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CardLayout",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _vm._v(" Report " + _vm._s(_vm.id) + ": "),
                  _c("strong", [_vm._v(_vm._s(_vm.address_string))]),
                  _c("div", { staticClass: "float-right" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-sm btn-info ml-1",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.printPage()
                          }
                        }
                      },
                      [
                        _c("CIcon", {
                          staticClass: "mr-1",
                          attrs: { name: "cil-print" }
                        }),
                        _vm._v(" Print ")
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "CRow",
            [
              _c(
                "CCol",
                [
                  _c(
                    "CCol",
                    { staticClass: "m-0 p-0", attrs: { sm: "12" } },
                    [_c("JobReportView", { ref: "report" })],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }