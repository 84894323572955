<template>
  <div>
    <CardLayout>
      <template #header>

        Report {{ id }}: <strong>{{ address_string }}</strong>
        <div class="float-right">
          <a href="#" class="btn btn-sm btn-info ml-1" @click="printPage()">
            <CIcon name="cil-print" class="mr-1" /> Print
          </a>
        </div>

      </template>
      <CRow>
        <CCol>
          <CCol sm="12" class="m-0 p-0">
            <JobReportView ref="report"> </JobReportView>
          </CCol>
        </CCol>
      </CRow>

    </CardLayout>
    
  </div>
  
</template>

<style scoped>
.full-height {
  height: 100%;
  min-height: 2000px;
  display: block;
}
</style>


<script>
import axios from "axios";

import JobReportView from "@/views/main/jobs/JobReportView";
import CardLayout from '@/layouts/CardLayout.vue';

const genericJobReportTemplate = "templates/jobreportv3.html";

const defaultSelectectItem = () => {
  return {
    title: "Demo Report",
    path: "templates/jobreportv3.html?",
    id: "443",
  };
};

export default {
  name: "ReportViewer",
  components: {
    JobReportView,
    CardLayout,
  },
  props: {
    selectedItem: {
      type: Object,
      default: defaultSelectectItem,
    },
  },

  data() {
    return {
      token: undefined,
      //selectedItem: {},
      reportData: Object,
    };
  },
  computed: {
    id() {

      return this.selectedItem ? this.selectedItem.id : 0;
    },
    path(){
      let ret = ""; 
      try {
        //return "" +  this.selectedItem.path;
        let params = {
          id: this.id, 
          token : this.token
        };

        let query = new URLSearchParams(params);
        
        let queryString = query.toString(); 
        ret = `https://demo1.aarcomm.io/demo/${this.selectedItem.path}?${queryString}`; 



      } catch (err) {
        console.log(err); 
      }
      return ret; 
    },
    selected() {
      return this.selectedItem ? this.selectedItem.title : "";
    },
    address() {
      try {
        let obj = this.reportData.data.siteAddress;
        return obj;
      } catch (err) {
        return null;
      }
    },

    address_string () {
      if (!this.address) {
        return "";
      }
      let street = "";
      if (this.address.street) {
        street = `${this.address.street},`
      }
      return `${street} ${this.address.city}, ${this.address.state}`;
    }
  },
  async mounted() {
    let reportId;
    try {
      reportId = this.$route.query["id"];

      if (!reportId) return;

      this.selectedItem.id = reportId;
    } catch (err) {
      console.log(err);
    }

    this.refresh();
  },
  activated() {
    let reportId;
    try {
      reportId = this.$route.query["id"];

      if (!reportId) return;
      if (this.selectedItem.id === reportId) {
        return; // The Same Report. Nothing to do
      }
      this.selectedItem.id = reportId;
    } catch (err) {
      console.log(err);
    }
    this.refresh();
  },
  methods: {
    findIframeByName(name) {
      return _.find(window.frames, (frame) => frame.name === name);
    },
    refresh() {
      let reportId = this.id;

      this.$api.getReportsByID(reportId, { id: reportId }).then((res) => {
        if (res.status === 200) {
          try {
            let reportData = res.data;
            
            this.selectedItem.data = reportData; // todo: what's the point of this line? 
            this.reportData = reportData;
            this.selectedItem.title = reportData.title; // should consider de-nest selectedItem: keep title field as data
            
            let reportView = this.$refs.report;

          
            reportView.setReportData(reportData.data);

            
            //this.selectedItem.title = reportData.title;
            if (reportData.path) {
              // Path to Report
              this.selectedItem.path = reportData.path;
            } else if (reportData.template) {
              // Template to Use : TODO: Dynamically Render View Models based on template
              reportData.path = reportData.template;
            } // Just Default to Generic Job Report
            else this.selectedItem.path = genericJobReportTemplate;
          } catch (er) {
            console.log(er);
          }
        }
      });
    },
    async getPage() {
      this.token = await this.$auth.getTokenSilently();

      let HTML = await axios.get(this.path, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        params: { id: this.id, token: this.token },
      });

    },
    printPage() {
      try {
        window.frames["reportIFrame"].print();
      } catch (err) {
        window.print();
      }
    },
  },
  watch: {
    selectedItem(val) {

      if (this.selectedItem != val) {
        this.refresh();
      }
    },
  },
};
</script>